<template>
  <div class="modal">
    <div class="modal-content">
      <h2>Réinitialiser votre mot de passe</h2>
      <div>
        <form @submit.prevent>
          <InputText
            v-model.trim="email"
            type="email"
            placeholder="vous@email.com"
          />
        </form>
        <Button
          style="margin-right: 5px; margin-top: 10px"
          label="Retour"
          icon="pi pi-undo"
          @click="$emit('close')"
        />
        <Button
          style="margin-top: 10px"
          label="Réinitialiser"
          icon="pi pi-question"
          @click="resetPassword()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { auth } from "@/firebase";

export default {
  data() {
    return {
      email: "",
    };
  },
  methods: {
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    async resetPassword() {
      if (!this.emailIsValid(this.email)) {
        this.$toast.add({
          severity: "error",
          summary: "Erreur de réinitialisation",
          detail: "Email incorrect",
          life: 6000,
        });
      } else {
        try {
          await auth.sendPasswordResetEmail(this.email);
          this.$toast.add({
            severity: "success",
            summary: "Réinitialisation envoyé",
            detail: "Email envoyé avec succès !",
            life: 3000,
          });
        } catch (err) {
          console.log(err.message);
          this.$toast.add({
            severity: "error",
            summary: "Erreur de réinitialisation",
            detail: "Pas d'utilisateur enregistré sous cet email",
            life: 10000,
          });
        }
      }
    },
  },
};
</script>