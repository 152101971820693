<template>
  <div id="login">
    <PasswordReset
      v-if="getmodulestatus() === 'password'"
      @close="setModule('login')"
    ></PasswordReset>
    <section>
      <div class="col2">
        <form v-if="getmodulestatus() === 'login'" @submit.prevent>
          <h1>S'authentifier</h1>
          <div>
            <label for="email1">Email</label>
            <InputText
              v-model.trim="loginForm.email"
              type="text"
              placeholder="vous@email.com"
              id="email1"
              class="input"
            />
          </div>
          <div>
            <label for="password1">Mot de passe</label>
            <InputText
              v-model.trim="loginForm.password"
              type="password"
              placeholder="******"
              id="password1"
              class="input"
            />
          </div>
          <Button
            style="margin: 10px"
            label="S'authentifier"
            icon="pi pi-check"
            @click="login()"
          />
          <br />
          <Button
            style="margin-right: 5px"
            label="Mot de passe oublié"
            icon="pi pi-question"
            @click="setModule('password')"
          />
        </form>
        <form v-if="getmodulestatus() === 'signup'" @submit.prevent>
          <h1>S'enregistrer</h1>
          <div>
            <label for="prenom">Prénom</label>
            <InputText
              type="text"
              v-model.trim="signupForm.prenom"
              placeholder="Jean"
              id="prenom"
              class="input"
            />
          </div>
          <div>
            <label for="nom">Nom</label>
            <InputText
              v-model.trim="signupForm.nom"
              type="text"
              placeholder="Bombeur"
              id="nom"
              class="input"
            />
          </div>
          <div>
            <label for="email2">Email</label>
            <InputText
              v-model.trim="signupForm.email"
              type="text"
              placeholder="vous@email.com"
              id="email2"
              class="input"
            />
          </div>
          <div>
            <label for="mobile">Mobile</label>
            <InputText
              v-model.trim="signupForm.mobile"
              type="text"
              placeholder="06XXXXXXXX"
              id="mobile"
              class="input"
            />
          </div>
          <div>
            <label for="password2">Mot de passe</label>
            <InputText
              v-model.trim="signupForm.password"
              type="password"
              placeholder="Min 6 caractères"
              id="password2"
              class="input"
            />
          </div>
          <img
          style="max-height: 200px; margin-top:20px"
          v-bind:src="url.replace('upload/', 'upload/h_200/')"
          />
          <br />
          <label for="upload">Photo de profil</label>
          <br />
          <UploadCloudinary
            style="margin: 10px"
            folder="user-photos"
            id="upload"
            iscamera
            iscrop
            @result="onUploaded($event)"
          />
          <br />
          <Button
            style="margin-right: 5px"
            label="Retour"
            icon="pi pi-undo"
            @click="setModule('login')"
          />
          <Button
            style="margin-left: 5px"
            label="Confirmer"
            icon="pi pi-check"
            @click="signUp()"
          />
        </form>
      </div>
    </section>
  </div>
</template>

<script>
import { store } from "../store";
import PasswordReset from "@/components/PasswordReset";

export default {
  components: {
    PasswordReset,
  },
  data() {
    return {
      loginForm: {
        email: "",
        password: "",
      },
      signupForm: {
        prenom: "",
        nom: "",
        email: "",
        mbile: "",
        password: "",
        photo: "",
      },
      modulestatus: "login", // or "signup" or "password"
      url: "",
    };
  },
  methods: {
    login() {
      if (this.loginForm.email === "" || this.loginForm.password === "") {
        this.$toast.add({
          severity: "error",
          summary: "Impossible de s'authentifier",
          detail: "Merci de remplir tous les champs",
          life: 6000,
        });
      } else if (!this.emailIsValid(this.loginForm.email)) {
        this.$toast.add({
          severity: "error",
          summary: "Impossible de s'authentifier",
          detail: "Email incorrect",
          life: 6000,
        });
      } else {
        store
          .dispatch("login", {
            email: this.loginForm.email,
            password: this.loginForm.password,
          })
          .catch(() => {
            this.$toast.add({
              severity: "error",
              summary: "Impossible de s'authentifier",
              detail: "Email ou mot de passe incorrect",
              life: 6000,
            });
          });
      }
    },
    signUp() {
      if (
        this.signupForm.prenom === "" ||
        this.signupForm.nom === "" ||
        !this.emailIsValid(this.signupForm.email) ||
        this.signupForm.mobile === "" ||
        this.signupForm.password === "" ||
        this.signupForm.photo === ""
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Impossible de créer le compte",
          detail: "Merci de remplir tous les champs correctement",
          life: 6000,
        });
      } else {
        store
          .dispatch("signup", {
            prenom: this.signupForm.prenom,
            nom: this.signupForm.nom,
            email: this.signupForm.email,
            mobile: this.signupForm.mobile,
            password: this.signupForm.password,
            photo: this.signupForm.photo,
            color: this.getRandomColor(),
          })
          .then(() => {
            this.$toast.add({
              severity: "success",
              summary: "Compte bien créé",
              detail: "Le compte a bien été créé",
              life: 3000,
            });
          })
          .catch((error) => {
            console.log(error);
            this.$toast.add({
              severity: "error",
              summary: "Impossible de créer le compte",
              detail: "Email déjà utilisé",
              life: 6000,
            });
          });
      }
    },
    emailIsValid(email) {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    },
    setModule(value) {
      this.modulestatus = value;
    },
    onUploaded(e) {
      this.signupForm.photo = e.secure_url.replace(
        "upload/",
        "upload/c_crop,g_custom/"
      );
      this.url = this.signupForm.photo;
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    getmodulestatus() {
      if (this.$route.query.islogin) {
        this.$router.replace({ query: "" });
        this.modulestatus = "signup";
      }
      return this.modulestatus;
    },
  },
};
</script>

<style>
.input {
  margin: 5px;
}
</style>